<template>
  <v-select
    :items="options"
    v-bind="$attrs"
    v-on="$listeners"
    dense
    hide-details
    outlined
  />
</template>

<script>
  import { FILE_ITEM_TYPES } from '@/consts/document'

  export default {
    data () {
      return {
        options: FILE_ITEM_TYPES
      }
    }
  }
</script>
